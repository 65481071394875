<template>
    <div style="height:100%;">

        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>

                                <a-col class="textal_r">
                                    <a-button type="primary" @click="showProduct()">添加商品 </a-button>
                                    <a-modal width="700px" v-model="modalVisible" title="任务类型">
                                        <div>
                                            <p style="text-align:center">
                                                <a-button type="primary" class="margin-r" @click="TaskTargetOne(1)" ghost>优惠券</a-button>
                                                <a-button type="primary" class="margin-r" @click="TaskTargetOne(2)" ghost>实物奖品</a-button>
                                                <a-button type="primary" class="margin-r" @click="TaskTargetOne(3)" ghost>返利现金</a-button>
                                            </p>
                                        </div>
                                        <div slot="footer"></div>
                                    </a-modal>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <!--<a-table :columns="columns" rowKey="ID" :data-source="dataList" :pagination="pagination" @change="pagerChange">-->
                        <a-table :columns="columns" rowKey="ID" :data-source="dataList" @change="pagerChange">
                            <span slot="action" slot-scope="text, record">
                                <a-button type="link" size="small" @click="DelRow(record.ID)">删除</a-button>
                            </span>
                        </a-table>
                    </a-layout>

                </div>
            </a-layout-content>

        </a-layout>
        <a-modal v-model="Product.IsDisplay"
                 title="商品选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onProductOk">
            <div style="width: 100%; height:auto;">
                <!--<ul class="form-search">
                    <li style="margin:0">
                        <label>名称</label>
                        <a-input placeholder="输入名称" v-model="Product.Name"></a-input>
                    </li>
                </ul>-->
                <div id="boxdiv" style="padding-top:16px;">
                    <!--<a-radio-group v-model="Product.ID" @change="onProductChange">
                        <table id="example2" class="table  table-hover">
                            <thead>
                                <tr>
                                    <th width="40"></th>
                                    <th width="100">商品图片</th>
                                    <th>名称</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in Product.tableData" :key="item.ID">
                                    <td>
                                        <a-radio :value="item.ID" :name="item.Name"></a-radio>
                                    </td>
                                    <td align="center">
                                        <img :src="Getsrc(item)" height="80" width="80" />
                                    </td>
                                    <td title="Name">{{item.Name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </a-radio-group>-->
                    <!--<a-checkbox-group  @change="onProductChange">-->
                        <table id="example2" class="table  table-hover">
                            <thead>
                                <tr>
                                    <th width="40"></th>
                                    <th width="100">商品图片</th>
                                    <th>名称</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in Product.tableData" :key="item.ID">
                                    <td>
                                        <a-checkbox :value="item.ID" @change="onProductChange"></a-checkbox>
                                    </td>
                                    <td align="center">
                                        <img :src="Getsrc(item)" height="80" width="80" />
                                    </td>
                                    <td title="Name">{{item.Name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    <!--</a-checkbox-group>-->
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script type="text/javascript">
    import "ant-design-vue/dist/antd.css";
    import "@/assets/css/yjht.css";

    import http from "@/Plugin/Http.js";

    export default {
        name: "PrizeList",
        data() {
            return {
                dataList: [],
                columns: [
                    {
                        title: "名称",
                        dataIndex: "ProductName",
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                        width: 200
                    }
                ],
                modalVisible: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                StoreState: "0",
                StoreType: "0",
                Product: {
                    ID: [],
                    IsDisplay: false,
                    Name: '',
                    tableData: [],
                    disabled: false,
                    ProductType: 0
                }

            };
        },
        methods: {
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            onProductOk: function () {
                var self = this;
                console.log("*********************");
                console.log(self.Product.ID);
                self.Product.IsDisplay = false;
                console.log("*********************");

                //
                var op = {
                    url: "/MarketingModule/M2Activity/AddPrizeGoodDetail",
                    data: {
                        poolid: this.$route.query.ID,
                        productid: self.Product.ID,
                        //productname: "1111",
                    },
                    OnSuccess: function () {
                        //self.Product.tableData = data.data;
                        self.loadData();
                    }
                };

                console.log(op);
                http.Post(op);

            },
            onProductChange(e) {
                var self = this;



                var num = self.Product.ID.indexOf(e.target.value);

                //self.Product.ID = e.target.value;
                if (e.target.checked) {
                    
                    if (num == -1) {
                        //for (var i = 0; i < self.Product.tableData.length; i++)
                        //{
                        //    if (self.Product.tableData[i].ID == e.target.value) {
                                
                        //        console.log(self.Product.tableData[i].Name);
                        //    }
                        //}

                        self.Product.ID.push(e.target.value);
                    }
                } else {
                    var index = self.Product.ID.indexOf(e.target.value);
                    if (num != -1) {
                        self.Product.ID.splice(index,1)
                    }
                }



                console.log(self.Product.ID);
                //console.log(e.target.checked);

            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/ProductList",
                    data: {
                        id: this.$route.query.ActivityID
                    },
                    OnSuccess: function (data) {
                        console.log("data--------------------");
                        console.log(data.data);
                        console.log(data.data.length);
                        console.log("data--------------------");

                        self.Product.tableData = data.data;

                    }
                };
                http.Post(op);
            },
            Getsrc(e) {
                var src = '#';
                if (e.ProductExImage.length > 0) {
                    src = e.ProductExImage[0].ImagePath;
                }
                return src;
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetPrizeGoodList",
                    data: {
                        id: this.$route.query.ID,// self.ID,
                        pageindex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        console.log("res");
                        console.log(res);
                        console.log("res");
                        self.dataList = res.data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            showProduct: function () {
                var self = this;


                self.Product.IsDisplay = true;
            },

            DelRow(id) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除该商品',
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/M2Activity/RemoveGoodDetail",
                            data: {
                                id: id
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            }
        },
        mounted() {
            this.getTableData();
            this.loadData();
        }
    }
</script>



